<template>
    <modal-lateral ref="modalProductosIncluidos" titulo="Productos incluidos por el cedis">
        <div class="row mx-0 px-3 pb-2 mb-4 border-bottom">
            <img :src="urlCedis" class="br-4 obj-cover" width="86" height="86" />
            <div class="col">
                <p class="f-16 text-general">
                    Ciudad de México
                </p>
                <div class="row mx-0 align-items-center mt-2">
                    <i class="icon-calendar f-15 text-general" />
                    <div class="col-auto px-2">
                        20 Jun - 10 Jul
                    </div>
                    <span class="text-general ml-auto mx-2">Activa</span>
                </div>
                <div class="row mx-0 mt-2">
                    <div class="mn-pill bg-fondo text-general">
                        <i class="icon-product f-15" />
                        <span class="mx-2">
                            3
                        </span>
                    </div>
                    <div class="mn-pill bg-general3 text-white">
                        <i class="icon-cash f-12 mr-2" />
                        <span class="f-17">$ 25.000.000</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="custom-scroll overflow-auto" style="height:calc(100vh - 183px)">
            <div class="row mx-0 px-3 mb-3">
                <div v-for="(pd, p) in productos" :key="p" class="col-12 mb-3">
                    <card-promocion full-width :data="pd" />
                </div>
            </div>
            <div class="row mx-0 justify-center f-16 mt-5">
                <div class="col-10">
                    <p class="text-center f-16 text-general">
                        El cedis aun no ha añadido <br /> productos a la promoción
                    </p>
                </div>
            </div>
        </div>
    </modal-lateral>
</template>

<script>
export default {
    components: {
        cardPromocion: () => import('../components/cardPromocion')
    },
    data(){
        return {
            urlCedis: 'https://918429.smushcdn.com/2325059/wp-content/uploads/2021/04/dos-empleados-recorriendo-un-almacen.jpg?lossy=1&strip=1&webp=1',
            productos: [
                {
                    nombreProducto: 'Vino tinto Gato Negro cabernet sauvignon',
                    presentacion: '750 ml',
                    valor: '$45.000',
                },
                {
                    nombreProducto: 'Vino tinto Gato Negro cabernet sauvignon 2',
                    presentacion: '7500 ml',
                    valor: '$455.000',
                }
            ]
        }
    },
    methods: {
        toggle(){
            this.$refs.modalProductosIncluidos.toggle();
        }
    }
}
</script>